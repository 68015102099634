import { Alert, Card, CardContent, CardHeader, Grid, Stack, TextField, Typography } from "@mui/material";
import { apolloMutationHookWrapper, apolloQueryHookWrapper } from "Api/GraphQL";
import EntityPath, { EntityTreeNodeDetails, entityTreeNodeToParams } from "Entities/EntityPath";
import {
  ChartData,
  ImplementationTarget,
  ImplementationTargetReportDate,
  ImplementationTargetReportGraphDataSeries,
  ImplementationTargetType,
  useEmailImplementationTargetReportMutation,
  useImplementationTargetReportDataDetailsQuery,
} from "GeneratedGraphQL/SchemaAndOperations";
import { useTranslation } from "react-i18next";
import ErrorMessage from "Shared/ErrorMessage";
import {
  ImplementationTargetHeadlineGraph,
  ImplementationTargetHeadlineStatBox,
  ImplementationTargetOnTrackBadge,
} from "./ImplementationTargetElements";
import { ImplementationTargetBreakdownSections } from "./ImplementationTargetBreakdownSections";
import { ReactElement } from "react";
import { TFunction } from "i18next";
import { ImplementationTargetId } from "Lib/Ids";
import { SectionHelpText } from "./ImplementationTargetForm";
import { ButtonWithSpinner } from "MDS/ButtonWithSpinner";
import { Form, useForm, useTextField } from "Shared/Form";
import { PickTypename } from "type-utils";
import { MinutesDailyProgressCalendarList } from "./MinutesDailyProgress";
import { useWithFeatureEnabled } from "Contexts/CurrentInstituteContext";

type TargetFields = Pick<
  ImplementationTarget,
  "id" | "name" | "description" | "targetType" | "initialCredits" | "startDate" | "softLaunchDate" | "target"
>;

export type TargetDetails = TargetFields & {
  entityTreeNode: EntityTreeNodeDetails;
  closestReportDateTo: Pick<ImplementationTargetReportDate, "period" | "endDate"> | null;
};

type FullReportProps = {
  target: TargetFields;
  entityTreeNode: EntityTreeNodeDetails;
  closestReportDateTo: Pick<ImplementationTargetReportDate, "period" | "endDate">;
  hideProviderInformation: boolean;
};

function LabelAndInfo({ label, data }: { label: string; data: string | null }): ReactElement {
  return (
    <>
      <Grid item sm={4} xs={12} key="">
        <Typography variant="h3">{label}</Typography>
      </Grid>
      <Grid item sm={8} xs={12}>
        <Typography>{data}</Typography>
      </Grid>
    </>
  );
}

function TargetFieldsExplanation(props: { target: TargetFields }) {
  const { t } = useTranslation(["implementation", "common"]);
  const { target } = props;
  return (
    <Card>
      <CardHeader title={t("implementation:details.configuration.title")} />
      <CardContent>
        <Grid container spacing={0.5} rowGap={0.5} paddingLeft="0.5em">
          <LabelAndInfo
            label={t("implementation:form.startDate.label")}
            data={t("common:date.tiny", { date: target.startDate })}
          />
          <LabelAndInfo
            label={t("implementation:form.softLaunchDate.label")}
            data={
              target.softLaunchDate
                ? t("common:date.tiny", { date: target.softLaunchDate })
                : t("implementation:form.softLaunchDate.notPresent")
            }
          />
          <LabelAndInfo
            label={t("implementation:form.target.label")}
            data={target.target?.toString() ?? t("implementation:form.target.notPresent")}
          />
          <LabelAndInfo
            label={t("implementation:form.initialCredits.label")}
            data={target.initialCredits?.toString() ?? t("implementation:form.initialCredits.notPresent")}
          />
        </Grid>
      </CardContent>
    </Card>
  );
}

function EmailReportPanel(props: {
  targetId: ImplementationTargetId;
  reportDate: Pick<ImplementationTargetReportDate, "period" | "endDate">;
  hideProviderInformation: boolean;
}) {
  const { t } = useTranslation(["implementation"]);

  const fields = {
    additionalEmailAddress: useTextField({ required: false }),
  };

  const [sendEmail, { remoteData }] = apolloMutationHookWrapper(
    (response) => response.implementationEmailImplementationTargetReport,
    useEmailImplementationTargetReportMutation()
  );

  const form = useForm({
    fields: fields,
    remoteData: remoteData,
    submit: () => {
      let additionalEmailAddresses: ReadonlyArray<string> | null = null;

      if (fields.additionalEmailAddress.value && fields.additionalEmailAddress.value.length > 0) {
        additionalEmailAddresses = [fields.additionalEmailAddress.value];
      }

      sendEmail({
        variables: {
          input: {
            implementationTargetId: props.targetId,
            dateAndPeriod: {
              endDate: props.reportDate.endDate,
              period: props.reportDate.period,
            },
            hideProviderInformation: props.hideProviderInformation,
            additionalEmailAddresses,
          },
        },
      });
    },
  });

  const successMessage = remoteData.caseOf({
    Success: (result) => {
      return (
        <Alert severity="success">
          {t("implementation:details.share.success", { emails: result?.sentToEmails })}
        </Alert>
      );
    },
    Failure: (message) => {
      return (
        <Alert severity="error">
          <ErrorMessage message={message.toError().message} />
        </Alert>
      );
    },
    _: () => null,
  });

  return (
    <Card>
      <CardHeader title={t("implementation:details.share.title")} />
      <CardContent>
        <Form onSubmit={form.onSubmit}>
          <Stack direction="column" spacing={1}>
            <SectionHelpText
              text={t(
                props.hideProviderInformation
                  ? "implementation:details.share.helpTextNoBreakdowns"
                  : "implementation:details.share.helpTextBreakdowns"
              )}
            />
            <TextField
              label={t("implementation:details.share.additionalEmailAddress")}
              onChange={fields.additionalEmailAddress.onChange}
              value={fields.additionalEmailAddress.value}
              variant="outlined"
            />
            <ButtonWithSpinner
              variant="contained"
              color="primary"
              type="submit"
              disabled={!form.isValid || remoteData.type === "Loading"}
              showSpinner={remoteData.type === "Loading"}
            >
              {t("implementation:details.share.sendEmail")}
            </ButtonWithSpinner>
            {successMessage}
          </Stack>
        </Form>
      </CardContent>
    </Card>
  );
}

function targetDescription(targetType: ImplementationTargetType, t: TFunction<["implementation"]>) {
  switch (targetType) {
    case ImplementationTargetType.COCM_NEW_ENROLLMENTS:
      return t("implementation:targets.COCM_NEW_ENROLLMENTS.description");
    case ImplementationTargetType.COCM_MONTHLY_BILLED_MINUTES:
      return t("implementation:targets.COCM_MONTHLY_BILLED_MINUTES.description");
    case ImplementationTargetType.COCM_MONTHLY_EXPECTED_BILLING:
      return t("implementation:targets.COCM_MONTHLY_EXPECTED_BILLING.description");
    case ImplementationTargetType.COCM_MONTHLY_EXPECTED_RVUS:
      return t("implementation:targets.COCM_MONTHLY_EXPECTED_RVUS.description");
    case ImplementationTargetType.COCM_MONTHLY_EXPECTED_VALUE_UNITS:
      return t("implementation:targets.COCM_MONTHLY_EXPECTED_VALUE_UNITS.description");
    case ImplementationTargetType.COCM_MONTHLY_EXPECTED_BILLABLE_MINUTES:
      return t("implementation:targets.COCM_MONTHLY_EXPECTED_BILLABLE_MINUTES.description");
    case ImplementationTargetType.COCM_MONTHLY_BILLING_EFFICIENCY:
      return t("implementation:targets.COCM_MONTHLY_BILLING_EFFICIENCY.description");
  }
}

export function ImplementationTargetFullReport(props: FullReportProps) {
  const { t } = useTranslation(["implementation", "common"]);
  const { target, entityTreeNode, closestReportDateTo } = props;
  const { remoteData } = apolloQueryHookWrapper(
    useImplementationTargetReportDataDetailsQuery({
      variables: {
        implementationTargetId: target.id,
        entityTreeNode: entityTreeNodeToParams(entityTreeNode),
        dateAndPeriod: {
          // If you don't splat it out then it'll put the __typename in and it'll error.
          period: closestReportDateTo.period,
          endDate: closestReportDateTo.endDate,
        },
      },
    })
  );

  return remoteData.caseOf({
    Loading: () => null,
    NotAsked: () => null,
    Failure: () => {
      return <>{t("implementation:card.errorLoadingChart")}</>;
    },
    Success: (result) => {
      if (result.implementationTargetReport) {
        const breakdowns = props.hideProviderInformation ? null : (
          <Grid item xs={12}>
            <ImplementationTargetBreakdownSections
              breakdowns={result.implementationTargetReport.breakdowns}
              breakdownMaxValue={result.implementationTargetReport.breakdownMaxValue}
              targetType={target.targetType}
              unitClass={result.implementationTargetReport.unitClass}
            />
          </Grid>
        );

        const statBoxes = result.implementationTargetReport.headlineStats.map((stat, index) => {
          return (
            <ImplementationTargetHeadlineStatBox
              endDate={props.closestReportDateTo.endDate}
              stat={stat}
              period={stat.period}
              key={index}
            />
          );
        });

        return (
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Card>
                <CardHeader
                  title={target.name}
                  subheader={
                    <>
                      <EntityPath includeInstitute={"when_solo"} entityTreeNode={props.entityTreeNode} />
                      {target.description}
                      <Typography>{targetDescription(target.targetType, t)}</Typography>
                    </>
                  }
                />
                <Grid container spacing={1}>
                  <Grid item xs={9}>
                    <ImplementationTargetHeadlineGraph
                      series={result.implementationTargetReport.graphData}
                      unitClass={result.implementationTargetReport.unitClass}
                      maxValue={result.implementationTargetReport.graphMaxValue}
                      targetType={target.targetType}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Stack direction={"column"} spacing={1}>
                      <ImplementationTargetOnTrackBadge onTrack={result.implementationTargetReport.onTrack} />
                      {statBoxes}
                    </Stack>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <MinutesTargetCalendars
                points={
                  result.implementationTargetReport.graphData.find(
                    (series) => series.seriesType === ImplementationTargetReportGraphDataSeries.DATA
                  )?.points
                }
                targetType={target.targetType}
                targetMinutes={target.target}
              />
            </Grid>
            {breakdowns}
            <Grid item xs={12}>
              <TargetFieldsExplanation target={props.target} />
            </Grid>
            <Grid item xs={12}>
              <EmailReportPanel
                targetId={props.target.id}
                reportDate={props.closestReportDateTo}
                hideProviderInformation={props.hideProviderInformation}
              />
            </Grid>
          </Grid>
        );
      } else {
        return <ErrorMessage message={t("common:unexpectedError")} />;
      }
    },
  });
}

type MinutesTargetCalendarsProps = {
  points: ReadonlyArray<PickTypename<ChartData, "date" | "value">> | undefined;
  targetType: ImplementationTargetType;
  targetMinutes: number | null;
};

function MinutesTargetCalendars(props: MinutesTargetCalendarsProps) {
  if (
    props.points === undefined ||
    props.targetType !== ImplementationTargetType.COCM_MONTHLY_BILLED_MINUTES ||
    props.targetMinutes === null ||
    props.points.length === 0 ||
    !useWithFeatureEnabled("enableCocmDailyProgressBar")
  ) {
    return null;
  }

  return (
    <Card>
      <CardContent>
        <MinutesDailyProgressCalendarList points={props.points} targetMinutes={props.targetMinutes} />
      </CardContent>
    </Card>
  );
}
