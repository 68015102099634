import React, { ReactElement } from "react";
import { Box, Stack, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { apolloQueryHookWrapper } from "Api/GraphQL";
import {
  FindingFlavor,
  FindingSortParameter,
  FindingsQuery,
  SortDirection,
  useFindingsQuery,
} from "GeneratedGraphQL/SchemaAndOperations";
import ErrorMessage from "Shared/ErrorMessage";
import { DigUnpacked } from "type-utils";
import { AssessmentNeedsReviewFinding } from "./Findings/AssessmentNeedsReviewFinding";
import { Section } from "CollaborativeCare/CaseConsult/Section";
import { MonthlyAssessmentNotCompletedFinding } from "./Findings/MonthlyAssessmentNotCompletedFinding";
import { TimeElapsedSinceInvitationFinding } from "./Findings/TimeElapsedSinceInvitationFinding";
import { usePanelFilter } from "Contexts/SelectedPanelContext";
import Paginator, { initPage } from "Shared/Paginator";
import { EndOfMonthPushFinding } from "./Findings/EndOfMonthPushFinding";
import { useCurrentProviderId } from "AppSession/AppSession";
import { useTestPatientViewability } from "Contexts/TestPatientViewabilityContext";

// This is just the type coming back from the query. For ease of plumbing,
// we'll be using this quite a bit. Individual components are responsible
// for checking that they have the data they need.
export type FindingDatagram = DigUnpacked<FindingsQuery, ["collaborativeCareFindings", "nodes"]>;

export function Findings(): ReactElement {
  const { t } = useTranslation(["common", "collaborativeCare"]);

  const testPatientViewAbility = useTestPatientViewability();

  const panelFilter = usePanelFilter();
  const [pagination, setPagination] = React.useState(initPage(10));
  const providerId = useCurrentProviderId();

  const { remoteData } = apolloQueryHookWrapper(
    useFindingsQuery({
      variables: {
        ...pagination,
        unresolvedOnly: true,
        forPanel: panelFilter,
        sortBy: FindingSortParameter.UPDATED_AT,
        sortDirection: SortDirection.DESC,
        providerId: providerId,
        testPatient: testPatientViewAbility,
      },
      pollInterval: 15000,
    })
  );

  return remoteData.caseOf({
    Loading: () => <Typography>{t("common:remoteData.notAsked")}</Typography>,
    NotAsked: () => <Typography>{t("common:remoteData.loading")}</Typography>,
    Failure: (error) => <ErrorMessage message={error.message} />,
    Success: (result) => {
      if (!result.collaborativeCareFindings || !result.nonPanelFindings) {
        return <ErrorMessage message={t("collaborativeCare:findings.genericQueryError")} />;
      }
      return (
        <Stack direction="column" spacing={1}>
          <FindingsElement
            findings={result.collaborativeCareFindings.nodes}
            totalFindingsCount={result.collaborativeCareFindings.totalCount}
            nonPanelFindingsCount={result.nonPanelFindings.totalCount}
          />
          <Paginator
            pagination={pagination}
            onChange={setPagination}
            pageInfo={result.collaborativeCareFindings.pageInfo}
          />
        </Stack>
      );
    },
  });
}

type FindingsElementProps = {
  findings: ReadonlyArray<FindingDatagram>;
  totalFindingsCount: number;
  nonPanelFindingsCount: number;
};

function FindingsElement(props: FindingsElementProps): ReactElement {
  const { t } = useTranslation(["common", "collaborativeCare"]);
  const theme = useTheme();

  const missingFindingsCount = props.nonPanelFindingsCount - props.totalFindingsCount;

  let findingElements = props.findings.map((finding) => {
    return <FindingElement key={finding.id.toString()} finding={finding} />;
  });

  if (findingElements.length == 0) {
    // We want to have some basic empty text if we don't actually have
    // any measurements to show. Otherwise, show the findings.
    const message =
      missingFindingsCount === 0
        ? t("collaborativeCare:findings.noFindings")
        : t("collaborativeCare:findings.hiddenPanelFindings", { count: missingFindingsCount });

    findingElements = [
      <Stack key="no-findings" direction="row" alignItems="center" justifyContent="center" marginTop="3rem">
        <Typography variant="h3" textAlign="center">
          {message}
        </Typography>
      </Stack>,
    ];
  } else if (missingFindingsCount > 0) {
    findingElements.push(
      <Stack
        key="hidden-findings"
        direction="row"
        alignItems="center"
        justifyContent="center"
        marginTop="3rem"
      >
        <Typography variant="caption" textAlign="center">
          {t("collaborativeCare:findings.hiddenPanelFindings", { count: missingFindingsCount })}
        </Typography>
      </Stack>
    );
  }

  const findingsCount = (
    <Box
      display={"flex"}
      borderRadius="50%"
      bgcolor={theme.palette.success.main}
      minHeight={"2.4em"}
      minWidth={"2.4em"}
      alignItems={"center"}
      justifyContent={"center"}
    >
      <Typography variant="body1" fontSize="1.25rem" color={theme.palette.common.white}>
        {props.totalFindingsCount}
      </Typography>
    </Box>
  );

  return (
    <Section title={t("collaborativeCare:findings.title")} actions={findingsCount}>
      <Stack direction="column" spacing={1} marginTop={1}>
        {findingElements}
      </Stack>
    </Section>
  );
}

type FindingProps = {
  finding: FindingDatagram;
};
// The purpose of this element is to look at the flavor of the finding
// and to decide which component should render it. In essence,
// it is a router.
export function FindingElement(props: FindingProps): ReactElement {
  let finding = <></>;
  switch (props.finding.flavor) {
    case FindingFlavor.ASSESSMENT_NEEDS_REVIEW:
      finding = <AssessmentNeedsReviewFinding finding={props.finding} />;
      break;
    case FindingFlavor.MONTHLY_ASSESSMENT_NOT_COMPLETED:
      finding = <MonthlyAssessmentNotCompletedFinding finding={props.finding} />;
      break;
    case FindingFlavor.TIME_ELAPSED_SINCE_INVITATION:
      finding = <TimeElapsedSinceInvitationFinding finding={props.finding} />;
      break;
    case FindingFlavor.END_OF_MONTH_PUSH:
      finding = <EndOfMonthPushFinding finding={props.finding} />;
      break;
  }
  return finding;
}
