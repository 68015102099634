import { Card, CardContent, CardHeader, Stack, Typography } from "@mui/material";
import {
  BusinessEventSortParameter,
  Patient,
  PatientSession,
  RecentFailedLoginsQuery,
  SortDirection,
  useRecentFailedLoginsQuery,
} from "GeneratedGraphQL/SchemaAndOperations";
import Page from "Layout/Page";
import { Link } from "MDS/Link";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import SortablePagableCollectionDataGrid, { DataGridCols } from "Shared/SortablePagableCollectionDataGrid";

const DEFAULT_SORT_PARAMS = {
  sortBy: BusinessEventSortParameter.EVENT_DATE,
  sortDirection: SortDirection.DESC,
};

export function FailedLoginsTable(): ReactElement {
  const { t } = useTranslation(["failedLogins"]);

  const columns: DataGridCols<RecentFailedLoginsQuery, ["businessEvents"]> = React.useMemo(() => {
    return [
      {
        field: "eventDate",
        headerName: t("failedLogins:headers.eventDate"),
        flex: 1,
        sortable: true,
        valueGetter: (_value, row) => t("failedLogins:eventDate", { date: row.eventDate }),
      },
      {
        field: "lastName",
        headerName: t("failedLogins:headers.lastName"),
        flex: 1,
        sortable: false,
        renderCell: (params) => (
          <LastNameColumn patient={params.row.patient} input={params.row.data.patientLastName} />
        ),
      },
      {
        field: "birthdate",
        headerName: t("failedLogins:headers.birthdate"),
        flex: 1,
        sortable: false,
        renderCell: (params) => (
          <BirthdateColumn patient={params.row.patient} input={params.row.data.patientDob} />
        ),
      },
      {
        field: "ssn",
        headerName: t("failedLogins:headers.lastSsn"),
        flex: 1,
        sortable: false,
        renderCell: (params) => (
          <SsnColumn patient={params.row.patient} input={params.row.data.patientLastSsn} />
        ),
      },
      {
        field: "session",
        headerName: t("failedLogins:headers.session"),
        flex: 1,
        sortable: false,
        renderCell: (params) => (
          <SessionLinkColumn patient={params.row.patient} session={params.row.patientSession} />
        ),
      },
    ];
  }, []);

  return (
    <Page browserTitle={t("failedLogins:pageTitle")}>
      <Card>
        <CardHeader title={t("failedLogins:title")} />
        <CardContent>
          <SortablePagableCollectionDataGrid
            queryHook={useRecentFailedLoginsQuery}
            queryVariables={{}}
            unwrapData={(response) => response?.businessEvents || null}
            colNameToSortParam={(field) =>
              field === "eventDate" ? BusinessEventSortParameter.EVENT_DATE : null
            }
            columns={columns}
            defaultPageSize={25}
            defaultSortParams={DEFAULT_SORT_PARAMS}
          />
        </CardContent>
      </Card>
    </Page>
  );
}

// This is too clever by half but it saves me some typing.
type ColumnProps<Field extends keyof Patient> = {
  patient: Pick<Patient, "__typename" | Field> | undefined | null;
  input: string | undefined | null;
};

type SessionLinkColumnProps = {
  patient: Pick<Patient, "__typename" | "id"> | undefined | null;
  session: Pick<PatientSession, "__typename" | "id" | "targetDate"> | undefined | null;
};

function SessionLinkColumn(props: SessionLinkColumnProps): ReactElement {
  const { t } = useTranslation(["common", "failedLogins"]);

  if (props.patient && props.session) {
    return (
      <Link to={`/provider/patients/${props.patient.id}/sessions/${props.session.id}`}>
        {t("date.shortTime", { date: props.session.targetDate })}
      </Link>
    );
  } else {
    return <Typography fontStyle="italic">{t("failedLogins:noSession")}</Typography>;
  }
}

// The logic in these components is pretty arbitrary, the goal here is just to mimic what the ember code does.

function LastNameColumn(props: ColumnProps<"lastName">): ReactElement {
  const { t } = useTranslation(["failedLogins"]);

  if (props.patient) {
    return <Typography fontStyle="italic">{t("failedLogins:noLastName")}</Typography>;
  } else {
    return <Typography>{props.input}</Typography>;
  }
}

function BirthdateColumn(props: ColumnProps<"dob">): ReactElement {
  const { t } = useTranslation(["common", "failedLogins"]);
  // The dates that we get out of the event data hash are in the form `YYYY-MM-DD`, with no time part or time zone.
  // Passing just that string to `new Date` or `Date.parse` parses it in UTC, which will often push it back to the
  // previous day. For example, parsing "2022-01-01" while in EDT gives you a date instance representing 7pm on Dec 31
  // 2021. Bizarrely, appending a zeroed time string to this causes it to be parsed in the local time zone. This is
  // extremely odd, but standard enough to be documented: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/parse#using_date.parse
  // The upshot of this is that we can get a Date object representing the correct day, which lets us do locale-aware
  // formatting on it through i18next. I suspect that moment.js was handling this for us in ember.
  const inputDate = props.input ? new Date(`${props.input}T00:00:00`) : null;
  const input = inputDate ? <Typography>{t("date.medium", { date: inputDate })}</Typography> : null;
  const expected = props.patient ? (
    <Typography fontStyle="italic">
      {t("failedLogins:expectedBirthdate", { date: props.patient.dob })}
    </Typography>
  ) : null;

  return (
    <Stack direction="column" spacing={0.5}>
      {input}
      {expected}
    </Stack>
  );
}

function SsnColumn(props: ColumnProps<"lastSsn">): ReactElement {
  const { t } = useTranslation(["failedLogins"]);
  const expected = props.patient ? (
    <Typography fontStyle="italic">
      {t("failedLogins:expectedSsn", { ssn: props.patient.lastSsn })}
    </Typography>
  ) : null;

  return (
    <Stack direction="column" spacing={0.5}>
      <Typography>{props.input}</Typography>
      {expected}
    </Stack>
  );
}
