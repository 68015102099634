import { Grid, Stack } from "@mui/material";
import Page from "Layout/Page";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { useIsMobile } from "Shared/Responsive";
import { Findings } from "./Findings";
import { DashboardTaskList } from "./BetterTaskList/DashboardTaskList";
import { INBOX_MESSAGES_FINDINGS_FLAG, useIsFrontendFlagEnabled } from "Contexts/FrontendFlagContext";
import { InboxMessagesFindings } from "./InboxMessagesFindings";
import { MinutesDailyProgress } from "Implementation/MinutesDailyProgress";
import { WithFeature } from "Contexts/CurrentInstituteContext";

export function CareManagerDashboard(): ReactElement {
  const { t } = useTranslation(["collaborativeCare"]);

  const columns = useIsMobile() ? 6 : 12;

  const useInboxFindings = useIsFrontendFlagEnabled(INBOX_MESSAGES_FINDINGS_FLAG);
  const findingsContent = useInboxFindings ? <InboxMessagesFindings /> : <Findings />;
  return (
    <Page browserTitle={t("collaborativeCare:careManagerDashboard.title")} supportsPanels>
      <Grid container columns={columns} spacing={2}>
        <ResponsiveProgressbarContainer>
          <MinutesDailyProgress />
        </ResponsiveProgressbarContainer>
        <Grid item xs={6}>
          <Stack direction="column" spacing={3}>
            <DashboardTaskList />
          </Stack>
        </Grid>
        <Grid item xs={6}>
          {findingsContent}
        </Grid>
      </Grid>
    </Page>
  );
}

function ResponsiveProgressbarContainer(props: React.PropsWithChildren) {
  if (useIsMobile()) {
    return (
      <WithFeature feature="enableCocmDailyProgressBar">
        <Grid item xs={6} paddingLeft="1rem" paddingRight="1rem">
          {props.children}
        </Grid>
      </WithFeature>
    );
  } else {
    return (
      <WithFeature feature="enableCocmDailyProgressBar">
        <Grid item xs={2} />
        <Grid item xs={8}>
          {props.children}
        </Grid>
        <Grid item xs={2} />
      </WithFeature>
    );
  }
}
