import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import SortablePagableCollectionDataGrid, {
  DataGridCols,
  DataGridRowClickHandler,
} from "Shared/SortablePagableCollectionDataGrid";
import {
  DataSourceRecordLogSortParameter,
  IntegrationDataSourceRecordLogHistoryQuery,
  IntegrationDataSourceRecordLogHistoryQueryVariables,
  SortDirection,
  useIntegrationDataSourceRecordLogHistoryQuery,
} from "GeneratedGraphQL/SchemaAndOperations";
import { Tooltip, Typography, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ImportHistoryId } from "Lib/Ids";
import {
  dataSourceRecordLogStatusDescriptionT,
  dataSourceRecordLogStatusT,
} from "GeneratedGraphQL/EnumTranslations";
import { DataSourceRecordLogHistoryFilters } from "./DataSourceRecordLogHistoryFilters";

type ImportHistoryListProps = {
  importHistoryId: ImportHistoryId;
  filters: DataSourceRecordLogHistoryFilters;
};

const DEFAULT_SORT_PARAMS = {
  sortBy: DataSourceRecordLogSortParameter.CREATED_AT,
  sortDirection: SortDirection.DESC,
};

export function DataSourceRecordLogHistoryList(props: ImportHistoryListProps): ReactElement | null {
  const { t } = useTranslation(["integrations", "enums", "common"]);
  const theme = useTheme();
  const navigate = useNavigate();

  const columns: DataGridCols<
    IntegrationDataSourceRecordLogHistoryQuery,
    ["integrationsDataSourceRecordLogs"]
  > = React.useMemo(() => {
    return [
      {
        field: "date",
        headerName: t("integrations:integrationLog.logs.date"),
        sortable: true,
        flex: 1,
        renderCell: (params) => {
          return t("common:date.tinyWithTime", { date: params.row.createdAt });
        },
      },
      {
        field: "status",
        headerName: t("integrations:integrationLog.logs.status"),
        sortable: true,
        flex: 1,
        renderCell: (params) => {
          const status = dataSourceRecordLogStatusT(params.row.status, t);
          const description = dataSourceRecordLogStatusDescriptionT(params.row.status, t);
          return (
            <Tooltip title={description}>
              <Typography>{status}</Typography>
            </Tooltip>
          );
        },
      },
    ];
  }, []);

  const queryVars: Pick<IntegrationDataSourceRecordLogHistoryQueryVariables, "importHistoryId" | "status"> = {
    importHistoryId: props.importHistoryId,
    status: props.filters.status ? [props.filters.status] : null,
  };

  const onClick: DataGridRowClickHandler<
    IntegrationDataSourceRecordLogHistoryQuery,
    ["integrationsDataSourceRecordLogs"]
  > = (params, event) => {
    // Calling `navigate` in this handler will override the effect of clicking on any links in the table, so the patient
    // name column's link does nothing. Checking isDefaultPrevented tells us if we're clicking on something that's
    // generating another event in addition to this click (like a link, or possibly other inputs, I haven't tested).
    // As long as we don't navigate during clicks on the link the link works fine.
    if (!event.isDefaultPrevented()) {
      const id = params.row.id;
      navigate(`log/${id}`);
    }
  };

  return (
    <SortablePagableCollectionDataGrid
      sx={{ "& .MuiDataGrid-row.Mui-selected": { backgroundColor: theme.palette.background.default } }}
      disableRowSelectionOnClick={false}
      queryHook={useIntegrationDataSourceRecordLogHistoryQuery}
      queryVariables={queryVars}
      unwrapData={(response) => response?.integrationsDataSourceRecordLogs || null}
      colNameToSortParam={(_field) => DataSourceRecordLogSortParameter.CREATED_AT}
      columns={columns}
      defaultPageSize={10}
      defaultSortParams={DEFAULT_SORT_PARAMS}
      onRowClick={onClick}
    />
  );
}
